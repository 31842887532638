import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Menu, MenuButton, SubMenuButton, MenuItem, MenuGroup, MenuSeparator, useMenuState } from '@twilio-paste/menu';
import Changelog from '@twilio-paste/menu/CHANGELOG.md';
import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { Paragraph } from '@twilio-paste/paragraph';
import { MediaObject, MediaBody, MediaFigure } from '@twilio-paste/media-object';
import { AttachIcon } from '@twilio-paste/icons/esm/AttachIcon';
import { ChevronDownIcon } from '@twilio-paste/icons/esm/ChevronDownIcon';
import { MoreIcon } from '@twilio-paste/icons/esm/MoreIcon';
import { SidebarCategoryRoutes } from '../../../constants';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { DoDont, Do, Dont } from '../../../components/DoDont';
import { InlineCode } from '../../../components/Typography';
import { defaultExample, groupingExample, subMenuExample, iconButtonExample, actionsExample, itemContentExample } from '../../../component-examples/MenuExamples.ts';
export const pageQuery = graphql`
  {
    allPasteComponent(filter: {name: {eq: "@twilio-paste/menu"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/components/menu/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name="Menu" categoryRoute={SidebarCategoryRoutes.COMPONENTS} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/components/menu" storybookUrl="/?path=/story/components-menu--default" data={props.data.allPasteComponent.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">Using alpha components in your product</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    Alpha components are ready for you to use in production. Components such as Menu are likely to not see any API
    changes. This is only an alpha due to the lack of documentation and minimal design. Feel free to share your
    experience using this component at #help-design-system or start a discussion on GitHub.
  </CalloutText>
        </Callout>
        <h2>{`Guidelines`}</h2>
        <h3>{`About Menu`}</h3>
        <Paragraph mdxType="Paragraph">
  {props.pageContext.frontmatter.description} This component includes a menu trigger (a button in our case) and a menu
  comprising of menu items that are shown upon actioning the trigger. A menu item can be used to perform an action, a
  page navigation or show a sub menu.
        </Paragraph>
        <p>{`Each menu item can only perform a single action.`}</p>
        <Callout variant="warning" mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">Do not nest multiple actions within a menu item</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    Please do not add additional actionable elements inside a menu item. They will not be keyboard accessible.
  </CalloutText>
        </Callout>
        <h2>{`Examples`}</h2>
        <h3>{`Basic menu`}</h3>
        <LivePreview scope={{
          useMenuState,
          Menu,
          MenuButton,
          MenuItem,
          MenuSeparator,
          ChevronDownIcon
        }} noInline mdxType="LivePreview">
  {defaultExample}
        </LivePreview>
        <h3>{`Menu with groups`}</h3>
        <p>{`Use MenuGroups to create hierarchy or logical groupings within longer menus.
MenuGroups have a visible group label that should describe the grouping clearly.
They can be given a prefix icon; please only use icons in a `}<a parentName="p" {...{
            "href": "/icons/usage-guidelines#specify-if-an-icon-is-decorative"
          }}>{`decorative manner`}</a>{`
and make the label descriptive standalone.`}</p>
        <p>{`Add separators between MenuGroups and other menu items.`}</p>
        <LivePreview scope={{
          useMenuState,
          Menu,
          MenuButton,
          MenuItem,
          MenuGroup,
          MenuSeparator,
          ChevronDownIcon,
          AttachIcon
        }} noInline mdxType="LivePreview">
  {groupingExample}
        </LivePreview>
        <h3>{`Menu with sub items`}</h3>
        <LivePreview scope={{
          useMenuState,
          Menu,
          MenuButton,
          SubMenuButton,
          MenuItem,
          MenuSeparator,
          ChevronDownIcon
        }} noInline mdxType="LivePreview">
  {subMenuExample}
        </LivePreview>
        <h3>{`Choosing a menu trigger`}</h3>
        <p>{`The `}<inlineCode parentName="p">{`MenuButton`}</inlineCode>{` is the standard Paste `}<a parentName="p" {...{
            "href": "/components/button"
          }}>{`Button`}</a>{` with some extra functionality. As a result it takes all the usual props the Paste Button takes, meaning you have full access to all the variants and styling options.`}</p>
        <p>{`For example, you can create an icon button menu trigger like so:`}</p>
        <LivePreview scope={{
          useMenuState,
          Menu,
          MenuButton,
          MenuItem,
          MenuSeparator,
          MoreIcon
        }} noInline mdxType="LivePreview">
  {iconButtonExample}
        </LivePreview>
        <h2>{`Composition notes`}</h2>
        <h3>{`Performing actions`}</h3>
        <p>{`A menu item can perform 2 basic tasks:`}</p>
        <ul>
          <li parentName="ul">{`trigger an action (switching a view between grid and list)`}</li>
          <li parentName="ul">{`trigger a page navigation (going to a new page)`}</li>
        </ul>
        <p>{`To do so, either set `}<inlineCode parentName="p">{`href`}</inlineCode>{` with a valid url to go to a new page, or set the `}<inlineCode parentName="p">{`onClick`}</inlineCode>{` as an event handler on the `}<inlineCode parentName="p">{`MenuItem`}</inlineCode>{`.`}</p>
        <LivePreview scope={{
          useMenuState,
          Menu,
          MenuButton,
          MenuItem,
          MenuSeparator,
          ChevronDownIcon
        }} noInline mdxType="LivePreview">
  {actionsExample}
        </LivePreview>
        <h3>{`Composing menu items`}</h3>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">Note</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    Menu item content is composible however you want. We'll have more complete best practice guidelines soon, but in the
    meantime here are some example compositions using Paste components.
  </CalloutText>
        </Callout>
        <LivePreview scope={{
          useMenuState,
          Menu,
          MenuButton,
          MenuItem,
          MenuSeparator,
          ChevronDownIcon,
          MediaObject,
          MediaBody,
          MediaFigure,
          AttachIcon,
          Text
        }} noInline mdxType="LivePreview">
  {itemContentExample}
        </LivePreview>
        <h2>{`Anatomy`}</h2>
        <h3>{`MenuButton`}</h3>
        <p>{`See the `}<a parentName="p" {...{
            "href": "/components/button"
          }}>{`button`}</a>{` component.`}</p>
        <h3>{`Menu`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default token`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Modifiable?`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`background-color`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`color-background-body`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`border-width`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`border-width-10`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`border-color`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`color-border-light`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`border-radius`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`border-radius-20`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`box-shadow`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`shadow`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`max-width`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`size-30`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`min-width`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`size-20`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding-top`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-30`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding-bottom`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-30`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`z-index`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`z-index-20`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
          </tbody>
        </table>
        <h3>{`SubMenuButton and MenuItem`}</h3>
        <h4>{`Default`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default token`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Modifiable?`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`color`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`color-text (default) color-text-weaker (disabled)`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding-top`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-30`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding-bottom`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-30`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding-left`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-90 (in-group) or space-70 (regular)`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding-right`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-90 (in-group) or space-70 (regular)`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`Hover & focused`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default token`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Modifiable?`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`color`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`color-text-link`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
          </tbody>
        </table>
        <h3>{`MenuGroup`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default token`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Modifiable?`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding-top`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-30`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding-bottom`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-30`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding-left`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-70`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding-right`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-70`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`color`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`color-text`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`font-weight`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`font-weight-bold`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
          </tbody>
        </table>
        <h3>{`MenuSeparator`}</h3>
        <p>{`See the `}<a parentName="p" {...{
            "href": "/components/separator"
          }}>{`separator`}</a>{` component.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Property`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default token`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Modifiable?`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding-top`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-40`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`padding-bottom`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`space-40`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
          </tbody>
        </table>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/menu - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Usage`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {Menu, MenuButton, MenuItem, MenuSeparator, useMenuState} from '@twilio-paste/menu';
import {ChevronDownIcon} from '@twilio-paste/icons/esm/ChevronDownIcon';

const PreferencesMenu = () => {
  const menu = useMenuState();
  return (
    <>
      <MenuButton {...menu} variant="primary">
        Preferences <ChevronDownIcon decorative />
      </MenuButton>
      <Menu {...menu} aria-label="Preferences">
        <MenuItem {...menu}>Settings</MenuItem>
        <MenuItem {...menu} disabled>
          Extensions
        </MenuItem>
        <MenuSeparator {...menu} />
        <MenuItem {...menu}>Keyboard shortcuts</MenuItem>
      </Menu>
    </>
  );
};
`}</code></pre>
        <h4>{`Props`}</h4>
        <h5>{`useMenuState`}</h5>
        <p>{`Pass these as part of an object to the `}<inlineCode parentName="p">{`useMenuState`}</inlineCode>{` hook.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`baseId`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`ID that will serve as a base for all the items IDs.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`rtl`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`orientation`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`horizontal, vertical, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`currentId`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string, null, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The current focused item `}<inlineCode parentName="td">{`id`}</inlineCode>{`.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`loop`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, horizontal, vertical`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`wrap`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, horizontal, vertical`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`visible`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Whether it's visible or not.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`animated`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`number, boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`placement`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`auto-start, auto, auto-end, top-start, top, top-end, right-start, right, right-end, bottom-end, bottom, bottom-start, left-end, left, left-start`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`gutter`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`number, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Offset between the reference and the popover on the main axis. Should not be combined with `}<inlineCode parentName="td">{`unstable_offset`}</inlineCode>{`.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <h5>{`useMenuState returned props`}</h5>
        <p>{`These props are returned by the state hook. You can spread them into this component (`}<inlineCode parentName="p">{`{...state}`}</inlineCode>{`) or pass them separately. You can also provide these props from your own state logic.`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`baseId`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`ID that will serve as a base for all the items IDs.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`baseId`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`ID that will serve as a base for all the items IDs.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`visible`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`boolean`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Whether it's visible or not.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`animated`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`number `}{`|`}{` boolean`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`modal`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`boolean`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Toggles Dialog's `}<inlineCode parentName="td">{`modal`}</inlineCode>{` state.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`animating`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`boolean`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Whether it's animating or not.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`stopAnimation`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`() =`}{`>`}{` void`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Stops animation. It's called automatically if there's a CSS transition.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`hide`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`() =`}{`>`}{` void`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Changes the `}<inlineCode parentName="td">{`visible`}</inlineCode>{` state to `}<inlineCode parentName="td">{`false`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`placement`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`"`}{`auto-start`}{`"`}{` `}{`|`}{` `}{`"`}{`auto`}{`"`}{` `}{`|`}{` `}{`"`}{`auto-end`}{`"`}{` `}{`|`}{` `}{`"`}{`top-start...`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Actual `}<inlineCode parentName="td">{`placement`}</inlineCode>{`.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`orientation`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`"`}{`horizontal`}{`"`}{` `}{`|`}{` `}{`"`}{`vertical`}{`"`}{` `}{`|`}{` undefined`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Defines the orientation of the composite widget.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`currentId`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string `}{`|`}{` null `}{`|`}{` undefined`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The current focused item `}<inlineCode parentName="td">{`id`}</inlineCode>{`.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`wrap`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`boolean `}{`|`}{` `}{`"`}{`horizontal`}{`"`}{` `}{`|`}{` `}{`"`}{`vertical`}{`"`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`If enabled, moving to the next item from the last one in a row or column will focus the first item in the next row or column and vice-versa.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`groups`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Group[]`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Lists all the composite groups with their `}<inlineCode parentName="td">{`id`}</inlineCode>{` and DOM `}<inlineCode parentName="td">{`ref`}</inlineCode>{`.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`items`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Item[]`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Lists all the composite items with their `}<inlineCode parentName="td">{`id`}</inlineCode>{`, DOM `}<inlineCode parentName="td">{`ref`}</inlineCode>{`, `}<inlineCode parentName="td">{`disabled`}</inlineCode>{` state and `}<inlineCode parentName="td">{`groupId`}</inlineCode>{` if any.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`setCurrentId`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`(value: SetStateAction`}{`<`}{`string `}{`|`}{` null `}{`|`}{` undefine...`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Sets `}<inlineCode parentName="td">{`currentId`}</inlineCode>{`.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`first`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`() =`}{`>`}{` void`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Moves focus to the first item.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`last`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`() =`}{`>`}{` void`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Moves focus to the last item.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`move`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`(id: string `}{`|`}{` null) =`}{`>`}{` void`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Moves focus to a given item ID.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`next`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`(unstable_allTheWay?: boolean `}{`|`}{` undefined) =`}{`>`}{` void`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Moves focus to the next item.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`previous`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`(unstable_allTheWay?: boolean `}{`|`}{` undefined) =`}{`>`}{` void`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Moves focus to the previous item.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <h5>{`Menu`}</h5>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`hideOnClickOutside`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`When enabled, user can hide the dialog by clicking outside it.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`disabled`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Same as the HTML attribute.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <h5>{`MenuArrow`}</h5>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`size`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string, number, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <h5>{`MenuButton`}</h5>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`disabled`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Same as the HTML attribute.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <h5>{`MenuDisclosure`}</h5>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`disabled`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Same as the HTML attribute.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <h5>{`MenuGroup`}</h5>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`label`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Names the grouping`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`icon`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`React.ReactNode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`One of our icon components. Must be decorative.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <h5>{`MenuItem`}</h5>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`disabled`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Same as the HTML attribute.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`id`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Same as the HTML attribute.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`onClick`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`() => void`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Same as the HTML attribute.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`href`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Same as the HTML attribute.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <h5>{`MenuItemCheckbox`}</h5>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`disabled`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Same as the HTML attribute.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`value`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string, number, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Checkbox's value is going to be used when multiple checkboxes share the same state. Checking a checkbox with value will add it to the state array.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`checked`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Checkbox's checked state. If present, it's used instead of `}<inlineCode parentName="td">{`state`}</inlineCode>{`.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`id`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Same as the HTML attribute.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`name`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`MenuItemCheckbox's name as in `}<inlineCode parentName="td">{`menu.values`}</inlineCode>{`.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <h5>{`MenuItemRadio`}</h5>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`disabled`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Same as the HTML attribute.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`value`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string, number, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Checkbox's value is going to be used when multiple checkboxes share the same state. Checking a checkbox with value will add it to the state array.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`checked`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Checkbox's checked state. If present, it's used instead of `}<inlineCode parentName="td">{`state`}</inlineCode>{`.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`id`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string, undefined`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Same as the HTML attribute.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`name`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`MenuItemRadio's name as in `}<inlineCode parentName="td">{`menu.values`}</inlineCode>{`.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      